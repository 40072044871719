export default {
  // ==================================================================================
  // This part is for mapping meta data object key names of the webpage name.
  // So you can use this object key name for passing it as the props of the meta component
  // when you render meta component in the every webpage
  // ==================================================================================
  dataKeyMaps: {
    HOME: "home",
    WHY_CHOOSE_CS: "whyChooseCS",
    GET_IN_TOUCH: "getInTouch",
    OUR_STORY: "ourStory",
    FAQ: "faq",
    PRODUCT_CLOSETS: "closets",
    FRANCHISE_OPPORTUNITIES: "franchiseOpp",
    PRIVACY_POLICY: "privacyPolicy",
    VIEW_ALL: "viewAll",
    LOCATIONS: "locations",
    LOCATION_CHANDLER_ARIZONA: "chandlerArizona",
    BLOG: "blog",
    FINANCING: "financing",
  },
  // ==================================================================================
  // It is meta data objects for the webpage
  // ==================================================================================
  home: {
    title: "Perfect Custom Kitchens & Baths - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classykitchenandbath.com/",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  locations: {
    title: "Our Locations - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classykitchenandbath.com/view-all-products",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  franchiseOpp: {
    title: "Franchise Opportunities - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classykitchenandbath.com/franchise-opportunities",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  privacyPolicy: {
    title: "Privacy Policy - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classykitchenandbath.com/privacy-policy",
    robots:
      "noindex",
  },
  viewAll: {
    title: "Our Products - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classykitchenandbath.com/view-all-products",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  whyChooseCS: {
    title: "Why Choose Us - Classy Kitchen & Bath",
    description:
      "For over 35 years Classy Kitchen & Bath has been creating custom home storage solutions that are both beautiful and functional. Each custom closet design is...",
    canonical: "https://classykitchenandbath.com/why-choose-classy-closets",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  getInTouch: {
    title: "Get in touch - Classy Kitchen & Bath - Free Consultation",
    description:
      "Request a FREE In-Home Consultation with one of our expert Professional Design Team Members by completing the form...",
    canonical: "https://classykitchenandbath.com/get-in-touch",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  ourStory: {
    title: "Our Story - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath has been improving the lives of families and businesses through well-organized closet storage solutions designed for optimal living. More...",
    canonical: "https://classykitchenandbath.com/our-story",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  faq: {
    title: "FAQs - Classy Kitchen & Bath",
    description:
      "FAQ's - I would love to see what Classy Kitchen & Bath can do for me. How do I get started?The quickest way to get started is to submit a “Start Your FREE...",
    canonical: "https://classykitchenandbath.com/frequently-asked-questions",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  careers: {
    title: "Career Opportunities - Classy Kitchen & Bath",
    description:
      "Classy Kitchen & Bath jobs, careers, and opportunities",
    canonical: "https://classykitchenandbath.com/careers",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // This part is the products pages group
  // ==================================================================================
  "kitchens": {
    title: "Custom kitchens - Classy kitchen and bath",
    description:
      "At Classy Kitchen & Bath, whether you're looking ofr a renovation, remodel, or a makeover, we can provide you with the kitchen you deserve. Arizona",
    canonical: "https://classykitchenandbath.com/products/kitchens",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "baths": {
    title: "Custom baths - Classy Kitchen and Bath",
    description:
      "At Classy Kitchen & Bath, we can help achieve your dream bathroom. Whether you remodel or renovate, contact us for a free consultation",
    canonical: "https://classykitchenandbath.com/products/baths",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "countertops": {
    title: "Custom Countertops - Classy Kitchen & Bath",
    description:
      "Classy Kitchen and Bath is the perfect place for your custom countertop manufacturing needs in Arizona.",
    canonical: "https://classykitchenandbath.com/products/countertops",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },

  // ==================================================================================
  // This part is the individual location pages group
  // ==================================================================================
  chandlerArizona: {
    title: "Chandler Custom Kitchens - Classy Kitchen & Bath",
    description: "Chandler, Arizona is the home of Classy Kitchen & Bath' corporate offices, manufacturing facilities, and one of the most beautiful closet design showrooms in...",
    canonical: "https://classykitchenandbath.com/locations/chandler",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },

  scottsdale: {
    title: "Scottsdale Custom Kitchens - Classy Kitchen & Bath",
    description: "Scottsdale Arizona is home to one of the most beautiful closet design showrooms in Arizona",
    canonical: "https://classykitchenandbath.com/locations/scottsdale",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },


  // ==================================================================================
  // Accessibility
  // ==================================================================================
  accessibility: {
    title: "Accessibility statement - Classy Kitchen & Bath",
    description: "Classy Kitchen & Bath is committed to making our website's content accessible and user friendly to everyone",
    canonical: "https://classykitchenandbath.com/accessibility-statement",
    robots: "noindex, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  materialsAndFinishes: {
    title: "Materials and finishes - Classy Kitchen & Bath",
    description: "Classy Kitchen & Bath has plenty of options for your materials and finishes needs",
    canonical: "https://classykitchenandbath.com/materials-and-finishes",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },

  financing: {
    title: "Financing - Classy Kitchen & Bath",
    description: "Classy Kitchen & Bath has attractive financing options available to help you afford your kitchen or bath.",
    canonical: "https://classykitchenandbath.com/landing/financing",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  holiday: {
    title: "Classy Kitchen and Bath Spring Sale",
    description: "Classy Kitchen and Bath is having a spring sale event! Get 15% off all white melamine! Don't miss out on this limited-time offer",
    canonical: "https://classykitchenandbath.com/spring-sale",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  }
};
